import React, { useEffect, useState } from 'react';
import { PropsDocument } from '../Documents/types';
import { urls } from '../../common';
import { useCompany } from '../../context/companyProvider';
import { useQuery } from 'react-query';
import axios from 'axios';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import numeral from 'numeral';
import Tooltip from '../../components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck, faDownload } from '@fortawesome/pro-solid-svg-icons';
import LoadingDots from '../../components/LoadingDots';
import { Link } from 'react-router-dom';
import { hexToRGBA } from '../../common/colours';
import Doughnut from '../../components/Charts/Doughnut';
import Table from '../../components/Table';
import Line from '../../components/Charts/Line';
import CompanyBreadcrumbs from '../Company/Breadcrumbs';
import { handleDownloadFile } from '../Documents/common';
import { FolderDropdownSelect } from '../Documents/FolderDropdownList';

function CompanyDashboard() {
    const { company } = useCompany();

    type category = {
        [key: string]: {
            name: string,
            setup: number,
            passed: number,
            upcoming: number,
            expired: number,
            sites: number,
        }
    }

    type TableProps = PropsDocument & { downloads: number };

    type StatsProps = {
        id: string,
        name: string,
        type: string,
        list_order: number,
        items: number,
        categories: category
        incidents: { month: string, count: number, incidents: number, accidents: number, label: string }[]
    }

    const [rowsData, setRowsData] = useState<StatsProps>();
    const [documentRowsData, setDocumentRowsData] = useState<TableProps[]>([]);

    const [folderID, setFolderID] = useState<string>('');


    const fetchURL = `${urls.remoteURL}get-stats`;
    const fetchURLDocs = `${urls.remoteURL}get-stats/documents`;

    const { isLoading, isError, data, refetch } = useQuery(`companies-stats-` + company?.id, () => axios.get(fetchURL));
    const { isLoading: isDocumentsLoading, isError: isDocumentsError, data: documents, refetch: refetchDocuments } = useQuery(
        `companies-popular-documents-` + company?.id,
        () => axios.get(fetchURLDocs, { params: { folderID } }));

    useEffect(() => {
        if (!isLoading && !isError) {
            setRowsData(data ? data?.data : []);
        }

    }, [data?.data]);

    useEffect(() => {
        if (!isDocumentsLoading && !isDocumentsError) {
            setDocumentRowsData(documents ? documents?.data : []);
        }

    }, [documents?.data]);

    useEffect(() => {
        if (!isDocumentsLoading) {
            refetchDocuments();
        }
    }, [folderID]);


    const columnHelper = createColumnHelper<TableProps>();

    const columns = [
        columnHelper.accessor('name', {
            cell: info => info.getValue(),
            header: 'Document Name',
            footer: 'Document Name',
            sortingFn: 'alphanumeric',
            meta: {
                className: 'text-left'
            }
        }),
        columnHelper.accessor('downloads', {
            cell: info => numeral(info.getValue()).format('0,0'),
            header: 'Downloads',
            footer: 'Downloads',
            sortingFn: 'alphanumeric',
            meta: {
                className: 'w-28 text-center'
            }
        }),
        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const actionCell = (info: CellContext<TableProps, string | undefined>) => {
        return (
            <div className="block sm:flex gap-1 justify-center">
                <Tooltip content={'Download Document'}>
                    <button
                        className="btn btn-orange"
                        onClick={() => {
                            handleDownloadFile({ documentID: info.getValue() as string, filename: info.row.original.download_name || info.row.original.filename as string });
                            refetch();
                        }}
                    ><span className="sr-only">Download File</span><FontAwesomeIcon icon={faDownload} fixedWidth /></button>
                </Tooltip>
            </div>
        )
    }

    if (isError) {
        throw { code: 500, message: 'Error getting company dashboard stats?' };
    }

    if (isLoading || !rowsData) {
        return (
            <LoadingDots />
        )
    }

    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between">
                <CompanyBreadcrumbs />
            </div>

            <div className="grid md:grid-cols-2 gap-4 lg:grid-cols-4 mb-4">

                {Object.keys(rowsData.categories).map(key => {
                    const renewal = rowsData.categories[key];
                    const count = renewal.passed + renewal.upcoming + renewal.expired + renewal.setup;

                    if (count === 0) { return null; }
                    const percentComplete = (renewal.upcoming as number + renewal.passed as number) / (count as number);

                    return (
                        <div key={key} className='card p-5 text-center'>
                            <Doughnut gauge={true} hideLegend={true} data={{
                                labels: ["Passed", "Upcoming", "Expired", "Setup"],
                                datasets: [
                                    {
                                        data: [renewal.passed, renewal.upcoming, renewal.expired, renewal.setup],
                                        backgroundColor: [
                                            hexToRGBA("#16a34a", 0.3),
                                            hexToRGBA("#f97316", 0.3),
                                            hexToRGBA("#dc2626", 0.3),
                                            hexToRGBA("#6b7280", 0.3),
                                        ],
                                        borderColor: hexToRGBA("#6b7280", 0.5),
                                        borderWidth: 2,
                                    },
                                ],
                            }} />
                            <div className="-mt-10">
                                <FontAwesomeIcon size="2x" icon={faBadgeCheck} className="text-green-600" />
                                <h3
                                    className=""
                                >
                                    {numeral(percentComplete).format('0%')}
                                </h3>
                            </div>
                            <h3 className='text-2xl mb-2'>{renewal.name}</h3>
                            <div className='flex gap-2'>
                                <Link
                                    className="btn btn-rhinoBlue block flex-1 "
                                    to={`category/${key}`}>
                                    Details
                                </Link>
                                {renewal.sites > 0 &&
                                    <Link
                                        className="btn btn-rhinoBlue block flex-1 "
                                        to={`category/${key}/locations`}>
                                        Locations
                                    </Link>
                                }
                            </div>
                        </div>
                    )
                })}

            </div>

            <div className="grid lg:grid-cols-2 gap-4">
                <div >
                    <div className="card p-5">
                        <div className="overflow-x-auto">
                            <h3 className="text-lg font-medium  text-gray-900">Accidents/Incidents:</h3>

                            <div className='w-full h-96 mb-10'>
                                <Line data={
                                    {
                                        labels: rowsData.incidents.map(x => x.label),
                                        datasets: [
                                            {
                                                label: 'Incidents',
                                                data: rowsData.incidents.map(x => x.incidents),
                                                borderColor: 'rgb(255, 99, 132)',
                                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                                tension: 0.5,
                                                pointRadius: 6,
                                                hoverRadius: 8,
                                            },
                                            {
                                                label: 'Accidents',
                                                data: rowsData.incidents.map(x => x.accidents),
                                                borderColor: 'rgb(53, 162, 235)',
                                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                                tension: 0.5,
                                                pointRadius: 6,
                                                hoverRadius: 8,
                                            },
                                        ],
                                    }
                                }
                                    displayLegend={false}
                                />
                            </div>
                            <Link className="btn btn-rhinoBlue w-full block text-center" to="../accidents/add">Report Accident/Incident</Link>
                        </div>
                    </div>
                </div>


                <div className="card p-5">
                    <div className="overflow-x-auto">
                        <h3 className="text-lg font-medium  text-gray-900"> Documents:</h3>
                        <Table
                            columns={columns}
                            defaultData={documentRowsData}
                            options={{
                                noData: 'No documents found, please try changing folder.',
                                hideExport: true, pageSize: 5, hidePageSize: true,
                                paginationRight: <FolderDropdownSelect value={folderID as string} onChange={setFolderID} defaultOption what="all" />,
                                compactFooter: true,
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CompanyDashboard;